import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import SEO from '../components/SEO/SEO';
import { wrapper, breakpoints } from '../utils/style';

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  li:nth-of-type(2n) {
    background-color: #eee;
  }
  a {
    display: block;
    padding: 3px 10px;
    text-decoration: none;
    color: #222;
  }
  a:hover,
  a:active {
    color: #000;
  }
  @media (max-width: ${breakpoints.md}) {
    a {
      margin: 7px 0;
      padding: 10px;
      font-size: 16px;
    }
  }
`;

const HtmlSitemap = ({ data }) => {
  const title = `${process.env.SITE_NAME_SHORT} HTML Sitemap`;
  const description = `HTML sitemap for ${process.env.SITE_NAME_SHORT}`;
  const Posts = data.allWordpressPost.edges?.map((edge) => (
    <li key={edge.node.id} className="post">
      <Link to={`${edge.node.slug}/`} dangerouslySetInnerHTML={{ __html: edge.node.title }} />
    </li>
  ));
  const Pages = data.allWordpressPage.edges?.map((edge) => (
    <li key={edge.node.id} className="page">
      {edge.node.slug === 'home' ? (
        <Link to="/" dangerouslySetInnerHTML={{ __html: edge.node.title }} />
      ) : (
        <Link
          to={edge.node.wordpress_parent > 0 ? `${edge.node.path}/` : `${edge.node.slug}/`}
          dangerouslySetInnerHTML={{ __html: edge.node.title }}
        />
      )}
    </li>
  ));
  const Categories = data.allWordpressCategory.edges?.map((edge) => (
    <li key={edge.node.id} className="category">
      <Link to={`${edge.node.slug}/`} dangerouslySetInnerHTML={{ __html: edge.node.name }} />
    </li>
  ));
  return (
    <Wrapper>
      <SEO title={title} description={description} />
      <h1>HTML sitemap</h1>
      <ul>
        {Pages}
        {Categories}
        {Posts}
      </ul>
    </Wrapper>
  );
};

export default HtmlSitemap;

export const pageQuery = graphql`
  query all {
    allWordpressCategory {
      edges {
        node {
          id
          name
          slug
          path
          link
        }
      }
    }
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          id
          slug
        }
      }
    }
    allWordpressPage(sort: { fields: [date] }) {
      edges {
        node {
          title
          id
          slug
          path
          wordpress_parent
        }
      }
    }
  }
`;
